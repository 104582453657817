import React from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';

import css from './BlockFooter.module.css';
import { injectIntl } from 'react-intl';

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const BlockFooter = injectIntl(props => {
  const { blockId, className, rootClassName, textClassName, text, options, intl } = props;
  const classes = classNames(rootClassName || css.root, className);
  const hasTextComponentFields = hasDataInFields([text], options);

  const textConverted = text?.content.replace(/'/g, '"');
  if (isJson(textConverted)) {
    let textStr = '';
    const object = JSON.parse(textConverted);
    for (let i = 0; i < object.length; i++) {
      const code = object[i]?.code;
      const link = object[i]?.link;
      textStr =
        textStr + `- [${intl.formatMessage({ id: 'Footer.linkText.' + code })} ](${link})\n`;
    }
    text.content = textStr;
  }

  return (
    <BlockContainer id={blockId} className={classes}>
      {hasTextComponentFields ? (
        <div className={classNames(textClassName, css.text)}>
          <Field data={text} options={options} />
        </div>
      ) : null}
    </BlockContainer>
  );
});

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockFooter.defaultProps = {
  className: null,
  rootClassName: null,
  textClassName: null,
  text: null,
  options: null,
};

BlockFooter.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  textClassName: string,
  text: object,
  options: propTypeOption,
};

export default BlockFooter;
